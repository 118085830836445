import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { topics, publications, treatments } from '../data/data';


import 'bootstrap/dist/css/bootstrap.min.css';


var headerText;
var items;


function setItemVars(curPage) {
    switch (curPage) {
        case "counseling":
            items = topics;
            headerText = "ייעוץ ואבחון";
            break;
        case "treatments":
            items = treatments;
            headerText = "טיפולים ופעולות";
            break;
        case "publication":
            items = publications;
            headerText = "מחקרים ופרסומים";
            break;
    }
}

export default function ItemsComponent({ curPage }) {
    setItemVars(curPage);

    return (
        <div className="container py-5">
            <h2 className="mb-4">{headerText}</h2>
            <div className="row">
                {items.slice(0, 3).map((item, index) => (
                    <div key={index} className="col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{item.title}</h5>
                                <p className="card-text">{item.text}</p>
                                <div className="text-start">
                                    <Link to={`/${curPage}/${encodeURIComponent(item.title)}`} target="_blank" rel="noopener noreferrer">להמשך קריאה</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export function ItemDetailComponent({ curPage }) {
    setItemVars(curPage);
    const { title } = useParams();
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        document.title += ` - ${headerText}`;
        const initialTopicIndex = items.findIndex(item => item.title === title);
        setSelectedTopic(initialTopicIndex !== -1 ? initialTopicIndex : null);
    }, []);



    if (title !== "all" && !items.find(item => item.title === decodeURIComponent(title))) {
        return <div className="container text-danger h3">{`${headerText} - "${title}" - לא נמצא`}</div>;
    }

    const handleTopicClick = (index) => {
        setSelectedTopic(index === selectedTopic ? null : index);
    };

    return (
        <div className="container">
            <h1 className="mb-4">{headerText}</h1>
            {items.map((item, index) => (
                <div key={index}>
                    <dl>
                        <dt className="h4 ms-2" role="button" onClick={() => handleTopicClick(index)}>{item.title}</dt>
                        {selectedTopic === index && (
                            <div>
                                <dd>{item.detailedText}</dd>
                                {item.link && (
                                    <dd>
                                        <a href={item.link} target="_blank" rel="noopener noreferrer">קישור</a>
                                    </dd>
                                )}
                            </div>
                        )}
                    </dl>
                </div>
            ))}
        </div>
    );
};
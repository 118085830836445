import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function ClinicComponent() {
    return (
        <div className="container py-5">
            <h2 className="mb-4">מרפאה</h2>
            <div className="row">
                <p>
                    ישנה חשיבות גדולה בפגישת ייעוץ קרדיולוגי אשר תבטיח לך ליווי אישי ומקצועי לאורך כל הדרך.
                    במידה ויוחלט על צורך בביצוע פעולה חודרנית, זאת תתבצע בבי"ח "אסותא" ברמת החייל.
                </p>
                <p>
                    פרופסור ערן לשם (מרפאה פרטית)
                    עובד עם הביטוחים המשלימים של "מכבי" ו"לאומית"
                    ניתן לקבל החזר מכל הביטוחים הפרטיים על הייעוץ הקרדיולוגי.
                </p>
                <p>
                    מדיקל וילג', קניון מול החוף, חדרה.
                    <span className="text-nowrap">
                        כתובת : <a className="" href="https://goo.gl/maps/jCio3cBpSHMoRYco9" target="_blank" rel="noreferrer">דן שכטמן
                            10, חדרה</a>&nbsp;
                        <i className="fas fa-map-marker-alt"></i></span>
                    <span className="mx-1">
                        בניין B, קומה 3. מרפאה נגישה. יש חנייה מתחת לבניין.
                    </span>
                </p>
                <p>
                    <span className="text-nowrap">
                        טלפון לקביעת תורים: <a href="tel:04-6000767">04-6000767</a>
                        &nbsp;<i className="fas fa-phone-square"></i>
                    </span>
                </p>

            </div>
        </div>
    );
}
import React, { useState } from 'react';
import { Link } from 'react-router-dom';


export function Navbar({ sectionItems }) {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light sticky-top">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        {/* <a className="navbar-brand" href="#"></a> */}
                        <button className="navbar-toggler" type="button" onClick={toggleNav} aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={`collapse navbar-collapse${isNavOpen ? ' show' : ''}`}>
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link" href="#about">אודות</a>
                                </li>
                                <li className="nav-item">
                                </li>
                                {sectionItems.map((sectionItem, index) => (
                                    <li key={index} className="nav-item">
                                        <Link to={`/${sectionItem.name}/all`} className="nav-link" target="_blank" rel="noopener noreferrer">{sectionItem.text}</Link>
                                    </li>
                                ))}
                                <li className="nav-item">
                                    <a className="nav-link" href="#clinic">מרפאה</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#contact">יצירת קשר</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </nav>
    );
}

export function Header() {
    return (
        <header className=" text-center">
            <div className="container">
                <h1>פרופסור ערן לשם</h1>
                <p className="lead pb-1">מנהל היחידה להפרעות קצב וקוצבי לב במרכז רפואי הלל יפה, חדרה</p>
            </div>
        </header>
    );
}

export function Footer() {
    return (
        <footer className="bg-dark text-light py-3">
            <div className="container text-center">
                <p>כל הזכויות שמורות &copy;</p>
            </div>
        </footer>
    );
}

import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function ContuctComponent() {
    return (
        <div className="container py-5">
            <h2 className="mb-4">יצירת קשר</h2>
            <div className="row">
                <h5>פרופסור ערן לשם - מומחה בקרדיולוגיה ובהפרעות קצב לב</h5>
                <div className="pt-2">
                    <span className="text-nowrap">
                        כתובת מרפאה: <a className="" href="https://goo.gl/maps/jCio3cBpSHMoRYco9" target="_blank" rel="noreferrer">דן שכטמן
                            10, חדרה</a>&nbsp;
                        <i className="fas fa-map-marker-alt"></i></span>
                    <span className="mx-1">|</span>
                    <span className="text-nowrap">
                        טלפון מרפאה: <a href="tel:04-6000767">04-6000767</a>
                        &nbsp;<i className="fas fa-phone-square"></i>
                    </span>
                    <span className="mx-1">|</span><span className="text-nowrap">
                        <a href="mailto:dr.eranleshem@gmail.com">dr.eranleshem@gmail.com</a></span>
                    &nbsp;<i className="fas fa-envelope"></i>
                </div>
            </div>
            <div className="pt-2">
                <a href="#" target="_blank" rel="noreferrer">קישור לאינפומד ובו אפשרות ליצירת קשר וקביעת תור</a>                </div>
            <div className="pt-2">שפות שירות: עברית, אנגלית
            </div>
        </div>
    );
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

import AboutComponent from "./components/AboutComponent"
import ClinicComponent from './components/ClinicComponent';
import ContactComponent from './components/ContactComponent';
import { Navbar, Header, Footer } from './components/_Common';
import ItemsComponent, { ItemDetailComponent } from './components/ItemsComponent';

const sectionItems =
  [{ name: "counseling", text: "ייעוץ ואבחון" },
  { name: "treatments", text: "טיפולים ופעולות" },
  { name: "publication", text: "מחקרים ופרסומים" }];


function About() {
  return (
    <section id="about" className="">
      <div className="container">
        <AboutComponent />
      </div>
    </section>
  );
}


function App() {
  return (
    <div dir="rtl">
      <div className="watermark">האתר בבנייה</div>
      <Routes>
        <Route path="/" element={<Navbar sectionItems={sectionItems} />} />
      </Routes>
      <Header />
      <main>
        <section id="about">
          <Routes><Route path="/" element={<About />} /></Routes>
        </section>

        {sectionItems.map((sectionItem, index) => (
          <section key={index} id={sectionItem.name}>
            <Routes>
              <Route path="/" element={<ItemsComponent curPage={sectionItem.name} />} />
              <Route path={`/${sectionItem.name}/:title`} element={<ItemDetailComponent curPage={sectionItem.name} />} />
            </Routes>
          </section>
        ))}

        <section id="clinic">
          <Routes><Route path="/" element={<ClinicComponent />} /></Routes>
        </section>
        <section id="contact">
          <Routes><Route path="/" element={<ContactComponent />} /></Routes>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import drLeshem from '../Images/DrLeshem.jpg';  // Import image here


export default function AboutComponent() {
    return (
        <div className="container">
            <h2 className="mb-4">אודות</h2>
            <div className="row">
                <div className="col-lg-4">
                    <img src={drLeshem} alt="פרופסור ערן לשם" className="img-fluid w-75" />
                </div>
                <div className="col-lg-8">
                    <h2>פרופסור ערן לשם</h2>

                    <p>
                        מנהל היחידה להפרעות קצב וקוצבי לב במרכז רפואי הלל יפה בחדרה.
                    </p>

                    <h3>השכלה</h3>
                    <p>
                        בוגר הפקולטה לרפואה באוניברסיטת בן גוריון בבאר שבע.
                        <br />בעל תואר שני בניהול מערכות בריאות, מבית הספר לניהול באוניברסיטת בן גוריון.
                    </p>

                    <h3>התמחות והשתלמות</h3>
                    <p>
                        התמחה ברפואה פנימית ובקרדיולוגיה במרכז הרפואי תל אביב.
                        <br />
                        השתלמות עמיתים מחקרית וקלינית באלקטרופיזיולוגיה (הפרעות קצב לב), בבית החולים- Beth Israel
                        Deaconess Medical Center המשויך ל- Harvard Medical School בבוסטון, ארה"ב.
                    </p>

                    <h3>תפקידים קודמים</h3>
                    <p>
                        פרופסור ערן לשם עבד כרופא בכיר להפרעות קצב במרכז דוידאי להפרעות קצב במכון הלב בשיבא והקים
                        את מרפאת ההפרעות הקצב במכון הלב בבי"ח מעייני הישועה.
                    </p>

                </div>
            </div>
        </div>
    );
};
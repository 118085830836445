// data.js

export const topics = [
    { title: 'נושא 1', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המלא מתוך הנושא', link: '' },
    { title: 'נושא 2', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'זהו טקסט דמי שמשמש למטרות עיצוב ופרסום. הטקסט הזה אינו מכיל משמעות ספציפית ונשמש כדי למלא מקום במסמך או בעמוד כשרוצים לראות איך המסמך או העמוד יראה עם תוכן. הטקסט מכיל מילים בעברית כדי להראות את הפרסום והעיצוב עם תוכן מבלי להכניס משמעות מיוחדת.', link: '' },
    { title: 'נושא 3', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המלא מתוך הנושא', link: '' },
    { title: 'נושא 4', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המלא מתוך הנושא', link: '#' },
];

export const publications = [
    { title: 'פרסום 1', text: 'תחילת הטקסט מתוך הפרסום', detailedText: 'הטקסט המלא מתוך הפרסום', link: '#' },
    { title: 'פרסום 2', text: 'תחילת הטקסט מתוך הפרסום', detailedText: 'הטקסט המלא מתוך הפרסום', link: '#' },
    { title: 'פרסום 3', text: 'תחילת הטקסט מתוך הפרסום', detailedText: 'הטקסט המלא מתוך הפרסום', link: '#' },
];

export const treatments = [
    { title: 'סוג טיפול ראשון', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המורחב של הטיפול הראשון', link: '#' },
    { title: 'סוג טיפול שני', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המורחב של הטיפול השני', link: '#' },
    { title: 'סוג טיפול שלישי', text: 'תחילת הטקסט מתוך הנושא', detailedText: 'הטקסט המורחב של הטיפול השלישי', link: '#' },
];
